import { useEffect, useState } from "react";
import { Row, Col, Button, Modal, Alert } from "react-bootstrap";
import ReactCreditCard from "react-credit-cards";
import { pad } from "../../../../utils/dates";
import { Card } from "../../../../model/Classes/Card";
import { Fetchable, Nullable, StateHandler } from "../../../../model/Utilities/Types";
import { useTranslation } from "react-i18next";
import i18n from "../../../../i18n";
import StripeCardForm from "./StripeCardForm";
import { Elements } from "@stripe/react-stripe-js";
import { Stripe, StripeElementLocale, StripeElementsOptions, loadStripe } from "@stripe/stripe-js";
import {
  cancelSetupIntent,
  createCustomer,
  createSetupIntent,
  retrieveCustomer,
} from "../../../../services/stripe/stripeService";
import { STRIPE_KEY } from "../../../..";
import { StripeCustomer } from "../../../../model/Classes/StripeCustomer";
import { useOnTabClicked } from "../../../../hooks";
import { FaCheck, FaPlus, FaTrashAlt } from "react-icons/fa";
import { toast } from "react-toastify";
import refreshIconAnimated from "../../../../resources/loadingIcon.svg";
import { Link } from "react-router-dom";

declare interface InvoiceCardProps {
  cards: Nullable<Card[]>;
  successfullAddCard: boolean;
  setSuccessfullAddCard: StateHandler<boolean>;
  changeDefaultCard: (card: Card) => Promise<void>;
  disableChangeDefault: boolean;
  deleteCard: (card: Card, setShowModal: StateHandler<boolean>) => Promise<void>;
  activeKey: string;
  setCardsState: StateHandler<Fetchable | "new" | "changed">;
  hasOutstandingPayments: boolean;
}

export const InvoiceCard = ({
  cards,
  setSuccessfullAddCard,
  successfullAddCard,
  changeDefaultCard,
  disableChangeDefault,
  deleteCard,
  activeKey,
  setCardsState,
  hasOutstandingPayments,
}: InvoiceCardProps) => {
  const [options, setOptions] = useState<StripeElementsOptions>();
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [cardToBeDeleted, setCardToBeDeleted] = useState<Nullable<Card>>(null);
  const [customer, setCustomer] = useState<StripeCustomer>();
  const [stripe, setStripe] = useState<Stripe | null>(null);

  const [cardFormState, setCardFormState] = useState<"none" | "working" | "ready">("none");

  const createNumber = (lastDigits: string, brand?: string) => {
    function amex() {
      return `${Array(11).fill("\u2217").join("")}${lastDigits}`;
    }
    function notAmex() {
      return `${Array(4).join(`${Array(5).join("\u2217")}`)}${lastDigits}`;
    }

    switch (brand) {
      default:
        return notAmex();
      case "amex":
        return amex();
      case "american_express":
        return amex();
    }
  };

  const trashCanClicked = (card: Card) => {
    setCardToBeDeleted(card);
    setShowDeleteModal(true);
  };

  const closeModal = () => {
    setCardToBeDeleted(null);
    setShowDeleteModal(false);
  };

  useEffect(() => {
    async function getData() {
      setStripe(await loadStripe(STRIPE_KEY));
    }
    getData();
    //eslint-disable-next-line
  }, []);

  useOnTabClicked(
    activeKey,
    ["paymentMethod"],
    () => {
      async function getData() {
        let customerRes = await retrieveCustomer();
        if (!customerRes.success || customerRes.data === undefined || customerRes.data === null) {
          customerRes = await createCustomer();
        }
        setCustomer(customerRes.data);
      }

      if (!customer) getData();
    },
    [customer]
  );

  const handleSetupStripe = async () => {
    setCardFormState("working");
    const setupIntent = await createSetupIntent({
      customer_id: customer!.customer_id,
    });
    if (!setupIntent.success) {
      toast.error(t("global.view.error"));
    }

    setOptions({
      locale: i18n.options.lng as StripeElementLocale,
      clientSecret: setupIntent.data,
      appearance: {
        variables: {
          fontFamily:
            "Nunito, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji,Segoe UI Symbol",
        },
      },
    });
    setCardFormState("ready");
  };

  const handleDestructStripe = async () => {
    if (!stripe || !options || !options.clientSecret) return;
    const toCancel = await stripe.retrieveSetupIntent(options.clientSecret);
    await cancelSetupIntent({ setup_intent_id: toCancel.setupIntent?.id });
  };

  const handleModalHide = async (confirmed?: boolean) => {
    if (confirmed) handleDestructStripe();
    setShowModal(false);
    setCardFormState("none");
  };

  const { t } = useTranslation("common", { i18n: i18n });
  return (
    <>
      <Modal
        show={showModal}
        onHide={() => {
          handleModalHide();
        }}
      >
        <Modal.Header>
          <Modal.Title>{t("components.userSettings.tabs.payment.modal.header")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {cardFormState !== "ready" ? (
            <>
              <div>
                {t("components.userSettings.tabs.payment.modal.body.terms.wattery")}{" "}
                <Link to="/payment-terms-of-service" target="_blank" rel="noopener noreferrer">
                  {`${t("components.userSettings.tabs.payment.buttons.paymentTos.check").toLowerCase()}`}
                </Link>{" "}
                {t("global.generalWords.and").toLowerCase()}{" "}
                <a href="https://stripe.com/legal/consumer" target="_blank" rel="noopener noreferrer">
                  {" "}
                  {t("components.userSettings.tabs.payment.modal.body.terms.stripe")}
                </a>
              </div>
              <br />
            </>
          ) : (
            <Elements stripe={stripe} options={options}>
              <StripeCardForm
                clientSecret={options?.clientSecret}
                customer={customer}
                successfullAddCard={successfullAddCard}
                setSuccessfullAddCard={setSuccessfullAddCard}
                setCardsState={setCardsState}
                handleModalHide={handleModalHide}
              />
            </Elements>
          )}
        </Modal.Body>
        <Modal.Footer>
          {hasOutstandingPayments ? (
            <Alert variant="warning">{t("global.alert.other.outstandingPayments")}</Alert>
          ) : null}
          <Button
            variant="success"
            hidden={cardFormState === "ready"}
            onClick={handleSetupStripe}
            disabled={cardFormState === "working"}
            onMouseDown={(e) => e.preventDefault()}
          >
            {cardFormState === "working" ? (
              <img
                className="spinner"
                src={refreshIconAnimated}
                alt="Loading"
                style={{
                  width: 30,
                }}
              />
            ) : (
              t("global.buttons.add.card")
            )}
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              handleModalHide(true);
            }}
          >
            {t("global.buttons.cancel")}
          </Button>
        </Modal.Footer>
      </Modal>
      <Row className="mb-3 mt-2 flex-and-center">
        <Col xs="auto" sm="auto">
          <h5>
            {cards!.length > 0
              ? t("components.userSettings.tabs.payment.userCards.has")
              : t("components.userSettings.tabs.payment.userCards.hasNot")}
          </h5>
        </Col>
      </Row>
      {cards ? (
        <Row className="flex-and-center mb-3">
          {cards.map((card: Card, idx) => {
            return (
              <Col xs="auto" sm="auto" key={idx}>
                <div className={`credit-card-wrapper ${card.is_default ? "default-card" : "unused-card"}`}>
                  <ReactCreditCard
                    cvc="***"
                    expiry={`${pad(card.expire_month)}/${pad(card.expire_year)}`}
                    number={createNumber(
                      card.partial_pan ? card.partial_pan : card.last4,
                      card.type ? card.type : card.brand
                    )}
                    name={""}
                    issuer={card.type ? card.type : card.brand}
                    preview
                  />
                  <Row>
                    {card.is_default ? (
                      <div className="default-card-icon">
                        <FaCheck /> {t("components.userSettings.tabs.payment.userCards.defaultCard")}
                      </div>
                    ) : (
                      <Button
                        className="default-card-button"
                        onClick={() => changeDefaultCard(card)}
                        disabled={disableChangeDefault}
                      >
                        {t("components.userSettings.tabs.payment.userCards.setDefaultCard")}
                      </Button>
                    )}
                    <Button className="delete-card-button" onClick={() => trashCanClicked(card)}>
                      <FaTrashAlt />
                    </Button>
                  </Row>
                  <Modal show={showDeleteModal} onHide={closeModal}>
                    <Modal.Header>
                      <Modal.Title>{t("components.userSettings.tabs.payment.modal.body.sure")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      {t("components.userSettings.tabs.payment.modal.body.deleteCard")}{" "}
                      {cardToBeDeleted
                        ? cardToBeDeleted.partial_pan
                          ? cardToBeDeleted.partial_pan
                          : cardToBeDeleted.last4
                        : ""}
                      ?
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="danger" onClick={() => deleteCard(cardToBeDeleted!, setShowDeleteModal)}>
                        {t("global.buttons.delete")}
                      </Button>
                      <Button variant="secondary" onClick={closeModal}>
                        {t("global.buttons.cancel")}
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
              </Col>
            );
          })}
          <Col xs="auto" sm="auto" className="order-last">
            <Button className="add-card-button" onClick={() => setShowModal(true)}>
              <FaPlus style={{ marginRight: "5px", height: "0.9rem" }} />
              {t("global.buttons.add.card")}
            </Button>
          </Col>
        </Row>
      ) : null}
    </>
  );
};
