import { createGlobalStyle } from "styled-components";
import { LightTheme } from "./theme";

/**
 * Global styles for the application
 */
//A supplied theme is required here apparently
export const GlobalStyles = createGlobalStyle<{
  theme: typeof LightTheme /*| darkTheme*/;
}>`
  html {
    margin: 0;
    padding: 0;
    height: 100%;
  }

  *, *::after, *::before {
    box-sizing: border-box;
    font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  }

  body {
    align-items: center;
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
    display: flex;
    min-height: 100vh;
    width: 100vw;
    justify-content: center;
    text-rendering: optimizeLegibility;
    transition: all 0.50s linear;
    overflow-x: hidden;
    
    @media (max-width: ${({ theme }) => theme.mobile}) {
      font-size: 1.5rem;
      text-align: center;
    }
  }

  #root {
    min-height: 100vh;
    min-width: 100vw;
  }

  #hamburger-icon {
    height: 0vh;
    @media (max-width: ${({ theme }) => theme.tablet}) {
      height: 10vh;
    }
  }

  .container {
    margin-bottom: 0;
  }

  .container-min-height-min-content {
    min-height: min-content !important;
  }
  
  .top-level-component {
    min-height: 100vh !important;
    align-items: start;
    justify-items: center;
    @media (max-width: ${({ theme }) => theme.tablet}) {
      min-height: 90vh !important;
    }
  }
  
  #component-margin {
    margin-top: 3rem;
    margin-bottom: 1rem;
    @media (max-width: ${({ theme }) => theme.tablet}) {
      margin-top: 0rem;
      margin-bottom: 0rem;
    }
  }
  
  .overflow-y-hidden {
    overflow-y: hidden !important;
  }

  .align-self-center {
    align-self: center !important;
  }
  
  .justify-self-center {
    justify-self: center !important;
  }

  .flex {
    display: flex;
  }

  .width-100 {
    min-width: 100vw;
  }

  .text-align-center {
    text-align: center;
  }

  .subtle {
    font-size: 95%;
    color: #00000088;
  }
  
  a {
    text-decoration: none;
    color: ${({ theme }) => theme.lightGreen};
    &:hover {
      text-decoration: underline;
      color: ${({ theme }) => theme.lightGreenHover};
    }
  }
  
  .btn-primary, .btn-success, .btn-primary:disabled, .btn-success:disabled {
    background-color: ${({ theme }) => theme.lightGreen};
    border-color: ${({ theme }) => theme.lightGreen};
    &:focus {
      box-shadow: ${({ theme }) => theme.btnPrimaryFocusBoxShadow};
    }
  }

  .btn-outline-primary, .btn-outline-success, .btn-outline-primary:disabled, .btn-outline-success:disabled {
    border-color: ${({ theme }) => theme.lightGreen};
    color: ${({ theme }) => theme.lightGreen};
  }

  .btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open>.dropdown-toggle.btn-primary, .show>.dropdown-toggle.btn-primary,
  .btn-success:hover, .btn-success:focus, .btn-success:active, .btn-success.active, .open>.dropdown-toggle.btn-success, .show>.dropdown-toggle.btn-success {
    background-color: ${({ theme }) => theme.btnPrimaryHover};
    border-color: ${({ theme }) => theme.btnPrimaryHover};
  }

  .btn-outline-primary:hover, .btn-outline-primary:focus, .btn-outline-primary:active, .btn-outline-primary.active, .open>.dropdown-toggle.btn-outline-primary, .show>.dropdown-toggle.btn-outline-primary,
  .btn-outline-success:hover, .btn-outline-success:focus, .btn-outline-success:active, .btn-outline-success.active, .open>.dropdown-toggle.btn-outline-success, .show>.dropdown-toggle.btn-outline-success {
    background-color: ${({ theme }) => theme.lightGreen};
    border-color: ${({ theme }) => theme.lightGreen};
  }

  .btn-primary:active {
    background-color: ${({ theme }) => theme.darkGreen};
  }

  .tooltip-inner {
    background-color: ${({ theme }) => theme.lightGreen} !important;
  }

  .react-bootstrap-table-pagination-list-hidden {
    display: none;
  }

  .nav-link {
    color: ${({ theme }) => theme.lightGreen};

    &:hover {
      color: ${({ theme }) => theme.lightGreen};
    }
  }

  .react-bootstrap-table  {
    overflow-x: auto;

    .table {
      table-layout: auto;
    }
  }

  .dropdown-menu {
    @media (max-height: ${({ theme }) => theme.mobileL}) {
      max-height: 8em;
    }
    max-height: 18em;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 10px;
  }

  .alert {
    display: inline-block !important;
    width: auto !important;
    height: auto !important;
  }

  .custom-link {
    justify-content: right;
    align-items: right;
    display: flex;
    @media (max-height: ${({ theme }) => theme.tablet}) {
      bottom: 20px;
      right: 20px;
      position: fixed;
    }
  }

  .disabled-cursor {
    pointer-events: none;
    cursor: default;
  }

  
  .react-toggle--focus .react-toggle-thumb {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  .react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  }

  .page-link {
    border-color: ${({ theme }) => theme.lightGreen};
    color: ${({ theme }) => theme.lightGreen};
  }

  .page-link.active, .active > .page-link {
    background: ${({ theme }) => theme.lightGreen};
    border-color: ${({ theme }) => theme.lightGreen};
  }

  .page-link.active, .active > .page-link {
    background: ${({ theme }) => theme.lightGreen};
    border-color: ${({ theme }) => theme.lightGreen};
  }
  .page-link:focus {
    box-shadow: ${({ theme }) => theme.btnPrimaryFocusBoxShadow};
  }

  .fit-td {
    width: 1%;
    padding-left: 2rem;
    padding-right: 2rem;
    white-space: nowrap;
  }

  .spinner {
    animation-name: spin;
    animation-duration: 500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  `;
