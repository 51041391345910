import { StateHandler } from "../../model/Utilities/Types";
import { SelectRowProps } from "react-bootstrap-table-next";

export function createSelectRowProps<T extends { [key: string]: any }>(
  filterKey: keyof T,
  stateHandler: StateHandler<T[]>
): SelectRowProps<T> {
  return {
    mode: "checkbox",
    clickToSelect: true,
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        stateHandler((old: T[]) => [...old, row]);
      } else {
        stateHandler((old: T[]) => old.filter((tran: T) => tran[filterKey] !== row[filterKey]));
      }
      isSelect = !isSelect;
    },
    onSelectAll: (isSelect, rows, e) => {
      isSelect ? stateHandler(rows) : stateHandler([]);
    },
  };
}
