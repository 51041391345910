import axios from "axios";
import { tokenRenewingPost } from "../tokenRenewingRequest";
axios.defaults.withCredentials = true;

/**
 * Service function responsible for deleting a card
 *
 * NOTE! See service_structure_comment.md for a detailed description of the structure of the function.
 * @returns response from the backend
 */
export const deleteCard = async (data: { card_id: string | number }) => {
  return await tokenRenewingPost("/api/user/cards/delete", data);
};
