import { useState, useEffect, SetStateAction } from "react";
import { Container } from "react-bootstrap";
import { StyledReporting } from "./Reporting.styled";
import {
  getAllTransactionsDefaultCompleted,
  sendInvoiceData,
  getInvoicesMadeByUser,
  groupUpdatePaymentMethodStatus,
} from "../../../services/transactions/Reporting/Reporting_service";
import {
  validateAndSearch,
  createDataObjectAndDate,
  createBasicData,
  extractTransactions,
  createPeriodData,
  extractEmails,
  sortOnInvoicingMethods,
  sortOnInvoicingMethod,
  combineTransactions,
} from "./ReportingUtils";
import { sendInvoicesToPaytrail } from "../../../services/paytrail/sendInvoicesToPaytrail";
import { logger } from "../../../utils/logger";
import { decodeAndMakeDate } from "../../../utils/dates";
import { toggleAlertsOff } from "../../../utils/toggleAlertsOff";
import { timer } from "../../../utils/timer";
import AreaSingle, { AreaBase, AreaReporting } from "../../../model/Classes/Area";
import { CompletedTransaction, ReportingTransaction } from "../../../model/Classes/Transaction";
import { ReportingInvoice } from "../../../model/Classes/Invoice";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";
import { Nullable, Period, ResultCode, SearchPeriod, SearchType } from "../../../model/Utilities/Types";
import User from "../../../model/Classes/User";
import { ReportingLayout } from "./ReportingLayout";
import StructuredResponse from "../../../model/Classes/StructuredResponse";
import { useParams } from "react-router";
import { chargeCard } from "../../../services/stripe/stripeService";
import { toast } from "react-toastify";

/**
 * Component responsible for handling functionality related the reporting page.
 *
 * When the component is initialized, the useEffect hook is run. The hook fetches all the completed and active transactions
 * for the users root area (and its subarea) for the previous month. Additionally, it sets the html date input fields to contain
 * the first day of the previous month at 00:00, and the last day of the previous month at 23:59. If this is successful,
 * it updates the showView state to 1 to show the reporting page to the user. If an error occured, it sets the showView state
 * to 0 notifying the user that an error occured.
 *
 * The search functionality for fetching reports has 5 different modes:
 * 1) Basic: this is when the user inputs the start and stop dates and times themselves and clicks the search button
 * 2) This month: this is when the user clicks the "This month" quick report button
 * 3) Previous month: this is when the user clicks the "Previous month" quick report button
 * 4) Previous quarter: this is when the user clicks the "Previous quarter" quick report button
 * 5) Previous year: this is when the user clicks the "Previous year" quick report button
 *
 * Each mode has its own corresponding helper function for handling its functionality.
 *
 * Additionally, the component has helper functions for handling refreshing active transactions, and remote and force stopping
 * an active transaction.
 * @returns view for the reporting page
 */
declare interface ReportingProps {
  user: User;
}
const Reporting = ({ user }: ReportingProps) => {
  const [allTransactions, setAllTransactions] = useState<ReportingTransaction[]>([]); //state for all the transactions that have been searched
  const [displayTransactions, setDisplayTransactions] = useState<ReportingTransaction[]>([]);
  const [selectedTransactions, setSelectedTransactions] = useState<ReportingTransaction[]>([]); //state for all the transactions that have been selected
  const [allSelectedTransactions, setAllSelectedTransactions] = useState<ReportingTransaction[]>([]); //state for all the separate transactions for each aggregated transaction that has been selected
  const [invoices, setInvoices] = useState<ReportingInvoice[]>([]); //state for all the invoices
  const [areas, setAreas] = useState<AreaReporting[]>([]); //state for all the areas the user has access to
  const [areaField, setAreaField] = useState(""); //state for the chosen area (area input field). At initialization, contain an object with a name property that is an empty string
  const [currArea, setCurrArea] = useState<AreaReporting>(() => {
    return areas.filter((a: AreaReporting) => {
      return a.name === areaField;
    })[0];
  });
  // const [completedTransactions, setCompletedTransactions] = useState<
  //   CompletedTransaction[]
  // >([]);
  const [showView, setShowView] = useState(-1); //state for showing the loading screen, error screen, or reporting screen
  const [selectedInvoiceMethod, setSelectedInvoiceMethod] = useState<Nullable<number>>(null);
  const [invoicingFilter, setInvoicingFilter] = useState(0);
  const [startDate, setStartDate] = useState(""); //state for the starting date in html date input format (YYYY-MM-DD)
  const [stopDate, setStopDate] = useState(""); //state for the stopping date in html date input format (YYYY-MM-DD)
  //const [startDateUTC, setStartDateUTC] = useState('');
  //const [stopDateUTC, setStopDateUTC] = useState('');
  const [rangeAreaName, setRangeAreaName] = useState(""); //state containing the string to show for which period the reports are for
  const [invalidDates, setInvalidDates] = useState(false); //state for showing an error alert notifying the user that the input start and stop dates are invalid
  const [searching, setSearching] = useState(false); //state for showing the loading animation (used when refreshing active transactions)
  const [searchComplete, setSearchComplete] = useState(false); //state for showing a checkmark (used when refreshing active transactions)
  const [notReportingSite, setNotReportingSite] = useState(false);
  const [noRowChosen, setNoRowChosen] = useState(false);
  const [createInvoiceSuccess, setCreateInvoiceSuccess] = useState(false);
  const [createInvoiceFail, setCreateInvoiceFail] = useState(false);
  const [pendingTransactionFail, setPendingTransactionFail] = useState(false);
  const [selectedInvoices, setSelectedInvoices] = useState<ReportingInvoice[]>([]); //state for all the invoices that have been selected
  const [noInvoiceRowChosen, setNoInvoiceRowChosen] = useState(false);
  const [sendToPaytrailSuccess, setSendToPaytrailSuccess] = useState(false);
  const [sendToPaytrailWarning, setSendToPaytrailWarning] = useState(false);
  const [sendToPaytrailFail, setSendToPaytrailFail] = useState(false);
  const [sendToPaytrailMessage, setSendToPaytrailMessage] = useState("");
  const [sendToPaytrailHistory, setSendToPaytrailHistory] = useState<string[]>([]);
  const [previousSearch, setPreviousSearch] = useState<SearchType>({
    period: "previous",
    time: "",
    displayTime: "",
  });
  const [cardsSuccess, setCardsSuccess] = useState(false);
  const [cardsFail, setCardsFail] = useState(false);
  const { t } = useTranslation("common", { i18n: i18n });
  const [companyInvoices] = useState<number[]>([]);
  const [hasCompanyInvoices, setHasCompanyInvoices] = useState(false);

  const [startTime, setStartTime] = useState("00:00");
  const [stopTime, setStopTime] = useState("23:59:59");

  const mapTransactions = (transactions: ReportingTransaction[]) => {
    if (user.user_level === 2) {
      setDisplayTransactions(combineTransactions(transactions));
    } else {
      const [priv, pub, blocked] = extractTransactions(transactions);
      //priv.push(free);
      priv.push(pub);
      if (Number(blocked.total_cost) > 1) priv.push(blocked);

      setDisplayTransactions(priv);
    }
    setAllTransactions(transactions);
  };

  const createDataForTransactions = (transactions: ReportingTransaction[]) => {
    if (transactions.length === 0) return null;

    return {
      reporting_area: currArea!.id,
      period_start: decodeAndMakeDate(startDate, startTime).toISOString(),
      period_end: decodeAndMakeDate(stopDate, stopTime).toISOString(),
      user_ids: transactions.map((tran) => tran.id),
      invoicing_method: transactions[0].invoicing_method,
    };
  };

  const checkCards = async () => {
    const user_ids: string[] = [];

    allTransactions.forEach((t) => {
      if (t.transaction_type !== 3 && t.transaction_type !== 0) user_ids.push(t.email);
    });

    const thing = await groupUpdatePaymentMethodStatus({
      user_emails: user_ids,
    });

    if (thing.success) {
      timer(setCardsSuccess, 3000);
    } else {
      timer(setCardsFail);
      logger(thing.data);
    }
  };
  //Get the month and year from the URL
  const month = useParams<{ month: string }>().month;
  const year = useParams<{ year: string }>().year;
  const handleSetSelectedTransactions = (transactions: SetStateAction<ReportingTransaction[]>) => {
    let nextState: ReportingTransaction[];
    if (typeof transactions === "function") {
      nextState = transactions(selectedTransactions);
    } else {
      nextState = transactions;
    }
    setSelectedTransactions(nextState);
    const real: ReportingTransaction[] = [];
    for (const email of extractEmails(nextState)) real.push(...allTransactions.filter((tran) => tran.email === email));
    setAllSelectedTransactions(real);
  };

  /**
   * When the component initializes, default the page to show the previous months reports for the users root area.
   * Additionally, set the start and stop date and time to to the previous months corresponding values.
   *
   * The hook initially uses the createDataObjectAndDate() utility function to create a data object containing two
   * ISO-String formatted dates for the starting and stopping time, as well as get the year, month, and last day of
   * the month in html date input format (year: YYYY, month: MM, day: DD). From these, it sets the html date input
   * fields correctly, and calls two service functions for sending the data to the backend. If it fails, log the
   * error messages and update the showView state to 0 showing an error alert notifying the user that an error occured.
   * If all is well, update the following states:
   * - allTransactions (for all the completed transactions)
   * - allActiveTransactions (for all the active transactions)
   * - areas (all the areas the user has access to)
   * - areaField (the dropdown menu for choosing an area, set to the users root area)
   * - rangeAreaName (string indicating which area the search was conducted for)
   * - rangeText (object { period: string, time: string, displayTime: string }).
   *   - period contains a specified period from QuickReports.
   *   - time is the time interval
   *   - displayTime is a parenthesized version of time if period is specified
   *
   * Lastly, update the showView state to 1 to show the reporting page to the user.
   */
  useEffect(() => {
    //Use the utility function createDataObjectAndDate() to create the data object containing two
    //ISO-String formatted dates for the start and stopping date, as well as the year, month
    //and lastDay of the previous month.
    const [data, year, month, lastDay, invoicingMethod] = createDataObjectAndDate();
    //Set the html date input fields to start from the first day of the previous month at 00:00 until the
    //last day of the previous month at 23:59
    setStartDate(`${year}-${month}-01`);
    setStopDate(`${year}-${month}-${lastDay}`);
    setSelectedInvoiceMethod(invoicingMethod);

    /**
     * Asynchronous helper function for calling two service functions that fetch the needed data from
     * the backend. If both succeed, update the correct states with all the data and set the showView
     * state to 1 to show the reporting page to the user
     */
    const getData = async () => {
      //Call the correct service functions to get the data needed
      const completed = await getAllTransactionsDefaultCompleted(data);
      //Check if both requests came back as successful
      if (completed.success) {
        //Additionally, sort the areas received from the backend into alphabetical order
        //and find the users root area. After this, update the areas and areaField states
        const areasReceived: AreaReporting[] = completed.data[1];
        const sortedAreas = areasReceived.sort((a, b) => (b.name!.toLowerCase() < a.name!.toLowerCase() ? 1 : -1));
        const potentialRoot = sortedAreas.filter((area: AreaReporting) => area.user_root)[0];
        let rootArea: AreaReporting;
        if (potentialRoot === undefined) {
          //Success, update the allTransactionsstates with the data
          setAllTransactions(completed.data[0]);
          setDisplayTransactions(completed.data[0]);
          rootArea = { name: "" } as AreaReporting;
        } else {
          rootArea = potentialRoot;
          mapTransactions(completed.data[0]);
        }

        setAreas(areasReceived);
        setAreaField(rootArea.name);
        setCurrArea(rootArea);

        //Update the rangeAreaName and rangeText states
        setRangeAreaName(rootArea.name);
        setPreviousSearch({
          time: `${year}-${month}-01 00:00 to ${year}-${month}-${lastDay} 23:59`,
          displayTime: `(${year}-${month}-01 00:00 to ${year}-${month}-${lastDay} 23:59)`,
          period: "previous",
        });

        setShowView(1); //Finally, set the showView state to 1 to show the reporting page to the user
      } else {
        //Check if the error came when getting the completed transactions
        if (!completed.success) {
          logger(completed.data);
        }
        //Set the showView state to 0 notifying the user that an error occured
        setShowView(0);
      }
    };

    getData(); //Call the asynchronous helper function, get reporting data
    getInvoices(); // Get invoice data
    //getSpotPendingCount(); // getting spot price pending count.
    //eslint-disable-next-line
  }, []);

  const getInvoices = async () => {
    //Call the correct service functions to get the data needed
    const invoices = await getInvoicesMadeByUser();
    //Check if both requests came back as successful
    if (invoices.success) {
      //Success, update the invoices state with the data
      setInvoices(invoices.data);
    } else {
      //Check if the error came when getting the completed transactions
      if (!invoices.success) {
        logger(invoices.data);
      }
    }
  };

  const createInvoices = async (withInvoiceMethodSpecified: Nullable<number>) => {
    toggleAlertsOff([
      setNoRowChosen,
      setCreateInvoiceFail,
      setCreateInvoiceSuccess,
      setNotReportingSite,
      setPendingTransactionFail,
    ]);
    //Check is here because of a previous problem where creating invoices from root
    //sent out receipts w/o information about the seller
    /* if (currArea.contract_id === 0) {
      timer(setNoAreaOwnerAlert);
      return;
    } */
    if (!currArea!.reporting_site) {
      timer(setNotReportingSite);
      return;
    }
    if (allSelectedTransactions.length === 0) {
      timer(setNoRowChosen);
      return;
    }

    //If invoicingmethod is "All"
    if (withInvoiceMethodSpecified === null) return createInvoicesAll();

    const toInvoice = sortOnInvoicingMethod(allSelectedTransactions, withInvoiceMethodSpecified);

    const res = await sendInvoiceData(createDataForTransactions(toInvoice));

    if (res.success) {
      timer(setCreateInvoiceSuccess);
    } else {
      timer(setCreateInvoiceFail);
      logger(res.data);
    }
    getInvoices();
  };

  const createInvoicesAll = async () => {
    toggleAlertsOff([setNoRowChosen, setCreateInvoiceFail, setCreateInvoiceSuccess, setNotReportingSite]);

    const [test, normal, paytrail] = sortOnInvoicingMethods(allSelectedTransactions);

    const testData = createDataForTransactions(test);
    const normalData = createDataForTransactions(normal);
    const paytrailData = createDataForTransactions(paytrail);

    let testRes = new StructuredResponse<any>(true, 0);
    let normalRes = new StructuredResponse<any>(true, 0);
    let paytrailRes = new StructuredResponse<any>(true, 0);

    if (testData) testRes = await sendInvoiceData(testData);
    if (normalData) normalRes = await sendInvoiceData(normalData);
    if (paytrailData) paytrailRes = await sendInvoiceData(paytrailData);

    if (testRes.success && normalRes.success && paytrailRes.success) {
      timer(setCreateInvoiceSuccess);
    } else {
      timer(setCreateInvoiceFail);
      logger(testRes.data + " " + normalRes.data + " " + paytrailRes.data);
    }

    getInvoices();
  };

  const handleShowChargeCardResponse = (
    res: { resultCode: ResultCode; message: string; data: any },
    user_invoice_id: number
  ) => {
    let ret: string;
    if (!res.message.includes("_")) return res.message;
    switch (res.resultCode) {
      case ResultCode.Ok:
        toast.success(
          t("components.reporting.messages.invoiceSuccess", {
            invoiceId: user_invoice_id,
          })
        );
        ret = t("components.reporting.messages.invoiceSuccess", {
          invoiceId: user_invoice_id,
        });
        break;
      case ResultCode.PartialOk:
        toast.warning(`${t(`global.alert.failure.stripe.${res.message}`)}, id: ${user_invoice_id}`);
        ret = `${t(`global.alert.failure.stripe.${res.message}`)}, id: ${user_invoice_id}`;
        break;
      default:
        toast.error(`${t(`global.alert.failure.stripe.${res.message}`)}, id: ${user_invoice_id}`);
        ret = `${t(`global.alert.failure.stripe.${res.message}`)}, id: ${user_invoice_id}`;
        break;
    }
    return ret;
  };

  const sendToPaymentServiceProvider = async () => {
    toast.dismiss();
    if (selectedInvoices.length === 0) {
      toast.warning(t("global.alert.failure.selectInvoice"));
      return;
    }

    for (const user_invoice of selectedInvoices) {
      if (user_invoice.payment_method_type === 1) {
        companyInvoices.push(user_invoice.user_invoice_id);
        continue;
      }
      const send = { user_invoice_id: user_invoice.user_invoice_id };
      const { data } = await chargeCard(send);

      const msg = handleShowChargeCardResponse(data, user_invoice.user_invoice_id);

      setSendToPaytrailHistory((sendToPaytrailHistory) => [...sendToPaytrailHistory, msg]);
    }
    if (companyInvoices.length > 0) toast.warning(createCompanyInvoicingMessage(companyInvoices));

    getInvoices();
  };

  function createCompanyInvoicingMessage(companyInvoices: number[]) {
    if (companyInvoices.length === 0) return;
    const start = companyInvoices.length === 1 ? "Invoice" : "Invoices";
    const end = companyInvoices.length === 1 ? "is a company invoice." : "are company invoices.";
    let invoices = "";
    for (let i = 0; i < companyInvoices.length; i++) {
      let conc = companyInvoices[i].toString();
      if (i !== companyInvoices.length - 1) conc = conc.concat(", ");
      invoices = invoices.concat(conc);
    }
    return `${start} ${invoices} ${end}`;
  }

  /**
   * Helper function for conducting a search with the "Basic" mode. First, the function creates a data object with
   * the html date input fields start and stop date and time for the chosen area. It does so using the
   * createBasicData() utility function. Lastly, it calls the validateAndSearch() utility function for validating the
   * input data, and if successful, conduct a "Basic" search with the given data.
   */
  const searchBasic = (area: AreaSingle) => {
    //Create the data input object needed for the validateAndSearch() utility function by calling the
    //createBasicData() utility function.
    const dataInput = createBasicData(area.id!, startDate, stopDate, startTime);

    //Validate the resulting data object and conduct a "Basic" search with the validated data.
    //NOTE! The second to fifth parameters for the function are all null, since they are flags indicating
    //different modes. If all of them are null, it indicates to the function that the "Basic" search mode
    //is active
    validateAndSearch(
      dataInput,
      "",
      setInvalidDates,
      area,
      setSearching,
      setSearchComplete,
      mapTransactions,
      setRangeAreaName,
      startTime,
      stopTime,
      selectedInvoiceMethod,
      invoicingFilter,
      setSelectedTransactions,
      previousSearch,
      setPreviousSearch,
      false
    );
  };
  /**
   * Helper function for conducting a search using QuickReports.
   * @param area The area in the dropdownfield
   * @param period The period specified
   */
  const search = async (area: AreaBase, period: Period | SearchPeriod) => {
    const dataInput = createPeriodData(area.id!, period);

    //Update the html input data states with the correct data
    setStartDate(dataInput.start_date);
    setStopDate(dataInput.display_stop_date);

    //Validate the resulting data object and conduct a "Previous month" search with the validated data.
    //NOTE! The third parameter is set to true, while the second, fourth, and fifth parameters for the function are all null,
    //since they are flags indicating different modes. Only the third parameter flag is supposed to be true when
    //conducting a "Previous month" search
    validateAndSearch(
      dataInput,
      period,
      setInvalidDates,
      area,
      setSearching,
      setSearchComplete,
      mapTransactions,
      setRangeAreaName,
      startTime,
      stopTime,
      selectedInvoiceMethod,
      invoicingFilter,
      setSelectedTransactions,
      previousSearch,
      setPreviousSearch,
      true
    );
  };

  /**
   * Initially, the component shows a loading screen. If an error occured when fetching data, an appropriate
   * error message is displayed. Otherwise, show the ReportingLayout component
   */
  return (
    <StyledReporting className="top-level-component">
      {showView === -1 ? (
        <>
          <h2 className="align-self-center">{t("global.view.loading")}</h2>
        </>
      ) : showView === 0 ? (
        <h2 className="align-self-center">{t("global.view.error")}</h2>
      ) : (
        <Container id="component-margin">
          <ReportingLayout
            allTransactions={allTransactions}
            displayTransactions={displayTransactions}
            selectedTransactions={selectedTransactions}
            handleSetSelectedTransactions={handleSetSelectedTransactions}
            areas={areas}
            searchBasic={searchBasic}
            areaField={areaField}
            setAreaField={setAreaField}
            startDate={startDate}
            setStartDate={setStartDate}
            stopDate={stopDate}
            setStopDate={setStopDate}
            invalidDates={invalidDates}
            searching={searching}
            rangeText={previousSearch}
            rangeAreaName={rangeAreaName}
            searchComplete={searchComplete}
            search={search}
            invoices={invoices}
            createInvoices={createInvoices}
            noRowChosen={noRowChosen}
            createInvoiceFail={createInvoiceFail}
            createInvoiceSuccess={createInvoiceSuccess}
            user={user}
            notReportingSite={notReportingSite}
            setSelectedInvoiceMethod={setSelectedInvoiceMethod}
            setInvoicingFilter={setInvoicingFilter}
            selectedInvoiceMethod={selectedInvoiceMethod}
            invoicingFilter={invoicingFilter}
            setSelectedInvoices={setSelectedInvoices}
            sendToPayTrail={sendToPaymentServiceProvider}
            sendToPaytrailMessage={sendToPaytrailMessage}
            sendToPaytrailHistory={sendToPaytrailHistory}
            currArea={currArea}
            setCurrArea={setCurrArea}
            checkCards={checkCards}
            cardsSuccess={cardsSuccess}
            cardsFail={cardsFail}
            companyInvoices={companyInvoices}
            hasCompanyInvoices={hasCompanyInvoices}

            //  startDateUTC={startDateUTC}
            //  setStartDateUTC={setStartDateUTC}
            //  stopDateUTC={stopDateUTC}
            //  setStopDateUTC={setStopDateUTC}
            // //  startTime={startTime}
            //  setStartTime={setStartTime}
            //  stopTime={stopTime}
            //  setStopTime={setStopTime}
          />
        </Container>
      )}
    </StyledReporting>
  );
};

export default Reporting;
