import { Col, Row, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable, { ColumnDescription } from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { parseISOString } from "../../../utils/dates";
import infoIcon from "../../../resources/infoIcon.svg";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";
import { ReportingInvoice } from "../../../model/Classes/Invoice";
import User from "../../../model/Classes/User";
import { InvoiceStatus, StateHandler } from "../../../model/Utilities/Types";
import filterFactory, { selectFilter } from "react-bootstrap-table2-filter";
import { useState } from "react";
import { createSelectRowProps } from "../../GeneralComponents/TableNextComponents";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";

declare interface ReportingInvoicesProps {
  invoices: ReportingInvoice[];
  setSelectedInvoices: StateHandler<ReportingInvoice[]>;
  user: User;
  sendToPayTrail: () => Promise<void>;
  sendToPaytrailHistory: any[];
}

export const ReportingInvoices = ({
  invoices,
  setSelectedInvoices,
  user,
  sendToPayTrail,
  sendToPaytrailHistory,
}: ReportingInvoicesProps) => {
  const { t } = useTranslation("common", { i18n: i18n });

  const [statusFilter] = useState(() =>
    Object.entries(InvoiceStatus).map(([k]) => {
      return {
        label: t(`components.reporting.tabs.invoices.table.invoiceStatus.${InvoiceStatus[k]}`),
        value: Number(k),
      };
    })
  );

  const columnsForTable: ColumnDescription<ReportingInvoice>[] = [
    {
      dataField: "user_invoice_id",
      text: t("components.reporting.tabs.invoices.table.id"),
    },
    {
      dataField: "reference_number",
      text: t("components.reporting.tabs.invoices.table.ref"),
      formatter: (cell, row, rowIndex, extraData) => `${row.reference_number ? row.reference_number : ""}`,
    },
    {
      dataField: "last_name",
      text: t("components.reporting.tabs.invoices.table.user"),
      formatter: (cell, row, rowIndex, extraData) => `${row.first_name} ${row.last_name}`,
      sort: true,
    },
    {
      dataField: "period_start",
      text: t("components.reporting.tabs.invoices.table.start"),
      formatter: (cell, row, rowIndex, extraData) => `${parseISOString(row.period_start).toLocaleString("en-FI")}`,
      sort: true,
    },
    {
      dataField: "period_end",
      text: t("components.reporting.tabs.invoices.table.stop"),
      formatter: (cell, row, rowIndex, extraData) => `${parseISOString(row.period_end).toLocaleString("en-FI")}`,
      sort: true,
    },
    {
      dataField: "kWh",
      text: t("components.reporting.tabs.invoices.table.energy"),
    },
    {
      dataField: "amount",
      text: t("components.reporting.tabs.invoices.table.total"),
      formatter: (cell, row, rowIndex, extraData) => `${row.amount.toFixed(2)} ${row.currency}`,
    },
    {
      dataField: "invoice_status",
      text: t("components.reporting.tabs.invoices.table.status"),
      formatter: (cell, row, rowIndex, extraData) =>
        t(`components.reporting.tabs.invoices.table.invoiceStatus.${InvoiceStatus[cell]}`),
      filter: selectFilter({
        options: statusFilter,
        withoutEmptyOption: false,
      }),
    },
  ];

  const selectRowProps = createSelectRowProps<ReportingInvoice>("reference_number", setSelectedInvoices);

  return (
    <>
      {user.user_level === 2 ? (
        <>
          <Row>
            <Col>
              <Button variant="primary" className="send-to-paytrail" onClick={() => sendToPayTrail()}>
                {t("components.reporting.tabs.invoices.buttons.toPaytrail")}
              </Button>
              {/*Create an info icon next to the Send to Paytrail button which shows the
              current session's result history.*/}
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id="payment-history-tooltip" data-html={true}>
                    {/*Using dangerouslySetInnerHTML in order to get line breaks to work*/}
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          sendToPaytrailHistory.length > 0
                            ? sendToPaytrailHistory.join("<br>")
                            : t("components.reporting.tabs.invoices.tooltip"),
                      }}
                    />
                  </Tooltip>
                }
              >
                <img className="infoIcon" src={infoIcon} alt="info" />
              </OverlayTrigger>
            </Col>
          </Row>
        </>
      ) : null}
      <Row>
        <ToolkitProvider bootstrap4 keyField="user_invoice_id" data={invoices} columns={columnsForTable}>
          {(props) => (
            <BootstrapTable
              striped
              bordered
              hover
              noDataIndication={t("components.reporting.tabs.completed.table.noData")}
              pagination={paginationFactory({
                hidePageListOnlyOnePage: true,
              })}
              selectRow={selectRowProps}
              {...props.baseProps}
              filter={filterFactory()}
            />
          )}
        </ToolkitProvider>
      </Row>
    </>
  );
};
