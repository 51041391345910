import { useMemo, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { InvoiceCard } from "./UserSettingsPaymentMethodComponents";
import { deleteCard } from "../../../../services/paytrail/deleteCard";
import { getCards } from "../../../../services/paytrail/getCards";
import { setDefaultCard } from "../../../../services/paytrail/setDefaultCard";
import { Card } from "../../../../model/Classes/Card";
import { Fetchable, ResultCode, StateHandler } from "../../../../model/Utilities/Types";
import { useTranslation } from "react-i18next";
import i18n from "../../../../i18n";
import {
  getInvoicesOutstanding,
  getPaymentsOutstanding,
} from "../../../../services/userSettings/getPaymentsOutstanding";
import { chargeUserInvoices } from "../../../../services/stripe/stripeService";
import { toast } from "react-toastify";
import { useOnTabClicked } from "../../../../hooks";
import { timer } from "../../../../utils/timer";

declare interface UserSettingsPaymentMethodProps {
  successfullAddCard: boolean;
  setSuccessfullAddCard: StateHandler<boolean>;
  activeKey: string;
}

/**
 * Shorthand for `value === params[0] || value === params[1] || ... || value === params[n-1]`
 * @param value
 * @param params
 * @returns
 */
function isInPartialUnionType<T extends {}>(value: T, ...params: T[]) {
  return params.includes(value);
}

/**
 * Used to preserve current (previous) order of an observable array
 * @param prev previous state of array
 * @param next incoming change of array
 * @param predicate
 */
function preserveSort<T extends {}>(prev: T[], next: T[], predicate: (arg: T, comp: T, index: number) => number) {
  next.sort((a, b) => {
    const a1 = prev.findIndex((c, idx) => predicate(c, a, idx));
    const b1 = prev.findIndex((c, idx) => predicate(c, b, idx));
    return a1 - b1;
  });
}

const UserSettingsPaymentMethod = ({
  successfullAddCard,
  setSuccessfullAddCard,
  activeKey,
}: UserSettingsPaymentMethodProps) => {
  const [cards, setCards] = useState<Card[]>([]);
  const [paymentsOutstanding, setPaymentsOutstanding] = useState<{ total_price: number }>();
  const [invoicesOutstanding, setInvoicesOutstanding] = useState<any[]>();
  const [showView, setShowView] = useState(-1);
  const [changeDefaultCardSuccess, setChangeDefaultCardSuccess] = useState(false);

  const [outstandingState, setOutstandingState] = useState<Fetchable | "charged">("none");
  const [cardsState, setCardsState] = useState<Fetchable | "new" | "changed">("none");

  const hasOutstandingInvoices = useMemo(() => {
    return Number(invoicesOutstanding?.length) > 0;
  }, [invoicesOutstanding]);

  const hasOutstandingPayments = useMemo(() => {
    return Number(paymentsOutstanding?.total_price) > 0;
  }, [paymentsOutstanding]);

  const { t } = useTranslation("common", { i18n: i18n });

  useOnTabClicked(
    activeKey,
    ["paymentMethod"],
    () => {
      const getData = async () => {
        const { data, success } = await getCards();
        if (success) {
          setShowView(1);
          //Sort default card first in list
          switch (cardsState) {
            case "none":
              data.sort((a, b) => b.is_default - a.is_default);
              break;
            case "changed":
              preserveSort(cards, data, (a, b, idx) => {
                //Reserve for paytrail backwards comp
                const pmidA = a.id ? a.id : a.payment_method_id;
                const pmidB = b.id ? b.id : b.payment_method_id;
                if (typeof pmidA !== typeof pmidB) {
                  return 0;
                }
                if (pmidA === pmidB) return idx;
                return 0;
              });
              break;
            case "new":
              data.sort((a, b) => a.is_default - b.is_default);
              break;
            default:
              break;
          }
          setCards(data);
          setCardsState("fetched");
        } else {
          setShowView(0);
        }
      };
      if (isInPartialUnionType<typeof cardsState>(cardsState, "changed", "new", "none")) getData();
    },
    [cardsState]
  );

  useOnTabClicked(
    activeKey,
    ["paymentMethod"],
    () => {
      async function getData() {
        const invoices = await getInvoicesOutstanding();
        const payments = await getPaymentsOutstanding();
        setPaymentsOutstanding(payments.data);
        setInvoicesOutstanding(invoices.data);
        setOutstandingState("fetched");
      }
      if (isInPartialUnionType<typeof outstandingState>(outstandingState, "none", "charged")) getData();
    },
    [outstandingState]
  );

  useOnTabClicked(
    activeKey,
    ["paymentMethod"],
    () => {
      const getData = async () => {
        const { data, success } = await chargeUserInvoices();

        //Something actually went wrong with the communiction
        if (!success) {
          toast.error(t("global.alert.failure.stripe.unable_to_charge"));
          setOutstandingState("error");
          return;
        }
        //No outstanding invoices
        if (data === undefined) {
          setOutstandingState("fetched");
          return;
        }
        setOutstandingState("charged");
        for (const d of data) {
          if (d.resultCode === ResultCode.Ok || d.resultCode === ResultCode.PartialOk) {
            toast.success(t(`global.alert.success.${d.message}`), { autoClose: false });
          } else {
            toast.error(t(`global.alert.failure.stripe.${d.message}`), { autoClose: false });
          }
        }
      };
      if (successfullAddCard || changeDefaultCardSuccess) getData();
    },
    [successfullAddCard, changeDefaultCardSuccess]
  );

  const changeDefaultCard = async (card: Card) => {
    toast.dismiss();
    if (!card.is_default) {
      const data = {
        card_id: card.id ? card.id : card.payment_method_id,
      };
      const res = await setDefaultCard(data);
      if (res.success && res.data.resultCode === 10) {
        toast.success(t("global.alert.success.defaultCardChange"));
        timer(setChangeDefaultCardSuccess, 1000);
        setCardsState("changed");
      } else {
        toast.error(t(`global.alert.failure.${res.data.message}`), { autoClose: false });
      }
    }
  };

  const handleDeleteCard = async (card: Card, setShowModal: StateHandler<boolean>) => {
    setShowModal(false);
    toast.dismiss();

    if (cards.length === 1 || card.is_default) {
      if (hasOutstandingPayments || hasOutstandingInvoices) {
        toast.warning(t("global.alert.failure.outstanding"));
        return;
      }
    }

    const data = {
      card_id: card.id ? card.id : card.payment_method_id,
    };

    const deleted = await deleteCard(data);
    if (deleted.success) {
      toast.success(t("global.alert.success.cardDelete"));
      setCardsState("none");
    } else {
      toast.error(t("global.alert.failure.cardDelete"));
    }
  };

  if (showView === -1) return <h2 className="align-self-center">{t("global.view.loading")}</h2>;
  if (showView === 0) return <h2 className="align-self-center">{t("global.view.error")}</h2>;

  return (
    <>
      <Row className="mb-3" style={{ justifyContent: "center" }}>
        <InvoiceCard
          cards={cards}
          successfullAddCard={successfullAddCard}
          setSuccessfullAddCard={setSuccessfullAddCard}
          changeDefaultCard={changeDefaultCard}
          disableChangeDefault={false}
          deleteCard={handleDeleteCard}
          activeKey={activeKey}
          setCardsState={setCardsState}
          hasOutstandingPayments={hasOutstandingInvoices}
        />
      </Row>
      <Row className="flex-and-center mt-2">
        <Col xs="auto" sm="auto">
          <p>{t("components.userSettings.tabs.payment.charge")}</p>
        </Col>
      </Row>
      <Row className="flex-and-center mt-2">
        <Col xs="auto" sm="auto">
          <a href="/payment-terms-of-service" target="_blank" rel="noreferrer">
            {t("components.userSettings.tabs.payment.buttons.paymentTos.check")}
          </a>
        </Col>
      </Row>
      <Row className="flex-and-center mt-2">
        <Col xs="auto" sm="auto">
          <div //icky wicky
            dangerouslySetInnerHTML={{
              __html: t("components.userSettings.tabs.payment.service.stripe", {
                interpolation: { escapeValue: false },
              }),
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default UserSettingsPaymentMethod;
