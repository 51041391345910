import { ResultCode } from "../../model/Utilities/Types";
import { tokenRenewingPost } from "../tokenRenewingRequest";

/**
 * Service function responsible for changing your default card
 *
 * NOTE! See service_structure_comment.md for a detailed description of the structure of the function.
 * @returns response from the backend
 */
export const setDefaultCard = async (data: { card_id?: string | number }) => {
  return await tokenRenewingPost<{ resultCode: ResultCode; data: any; message?: string }>(
    "/api/user/cards/setDefault",
    data
  );
};
