import { Col, Row, Button } from "react-bootstrap";
import { ColumnDescription } from "react-bootstrap-table-next";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";
import { AreaReporting, AreaBase } from "../../../model/Classes/Area";
import { ReportingTransaction } from "../../../model/Classes/Transaction";
import User from "../../../model/Classes/User";
import { StateHandler, Nullable, Period } from "../../../model/Utilities/Types";
import checkmarkIcon from "../../../resources/checkmarkIcon.svg";
import refreshIconAnimated from "../../../resources/refreshIconAnimated.gif";
import { QuickReports } from "./QuickReports";
import { TransactionsFilters } from "./TransactionsFilters";
import { TransactionTable } from "./TransactionTable";
import { createSelectRowProps } from "../../GeneralComponents/TableNextComponents";
import { SetStateAction, useState } from "react";

export interface ReportingCompletedTransactionsProps {
  areas: AreaReporting[];
  allTransactions: ReportingTransaction[];
  displayTransactions: ReportingTransaction[];
  selectedTransactions: ReportingTransaction[];
  handleSetSelectedTransactions: (transactions: SetStateAction<ReportingTransaction[]>) => void;
  areaField: string;
  startDate: string;
  stopDate: string;
  setAreaField: StateHandler<string>;
  setStartDate: StateHandler<string>;
  setStopDate: StateHandler<string>;
  searchBasic: (area: AreaBase) => void;
  search: (area: AreaBase, period: Period) => Promise<void>;
  searching: boolean;
  searchComplete: boolean;
  invalidDates: boolean;
  rangeAreaName: string;
  rangeText: { period: string; displayTime: string; time: string };
  headers?: { label: string; key: string }[];
  createInvoices: (withInvoiceMethodSpecified: Nullable<number>) => void;
  noRowChosen: boolean;
  createInvoiceFail: boolean;
  createInvoiceSuccess: boolean;
  user: User;
  notReportingSite: boolean;
  setSelectedInvoiceMethod: StateHandler<Nullable<number>>;
  setInvoicingFilter: StateHandler<number>;
  selectedInvoiceMethod: Nullable<number>;
  invoicingFilter: number;
  currArea: AreaReporting;
  setCurrArea: StateHandler<AreaReporting>;
  checkCards: () => Promise<void>;
  cardsSuccess: boolean;
  cardsFail: boolean;
}
/**
 * Component responsible for displaying the content in the completed transactions tab in Reporting.
 * The component can be divided into two main categories:
 * 1) The left side view
 * 2) The right side view
 *
 * The left side view contains:
 * - An input where the user can choose which area and its subareas they want to look at the reports for
 * - Inputs for the starting date + time and stopping date + time (html date input)
 * - A search button
 * - A quick reports view where the user can choose to get reports quickly for this month, the previous month,
 *   previous quarter, and the previous year
 *
 * The right side view contains:
 * - The area the search was conducted for
 * - The period the search was conducted for
 * - A link that exports the report to a .csv file
 * - A table containing the reports. The table has 3 columns: the name of the people who have charged at this area or
 *   in any of this areas subareas, their total kWh, and their total cost.
 *
 * When the user accesses the Reporting page, reports for the previous month for the users root area is shown.
 *
 * Whenever a user conducts a search (clicks the search button), the search button turns into a loading animation and
 * the search button nor the quick reports buttons are disabled until the search is finished. This is done to prevent
 * the user from spamming the search button or a quick report button, which could lead in unwanted results (overloaded
 * traffic etc.). When the search is completed, the search button becomes a checkmark icon for 2 seconds. After this,
 * it turns back into the search button and the search button and the quick report buttons can be clicked again.
 * @param {*} areas state containing all the areas the user has access to
 * @param {*} allTransactions state containing all the transactions for the report
 * @param {*} areaField state for the input area containing the area name, area id, and user root flag
 * @param {*} startDate state containing the start date in html date input format (YYYY:MM:DD)
 * @param {*} startTime state containing the start time in html date input format (HH:MM)
 * @param {*} stopDate state containing the stop date in html date input format (YYYY:MM:DD)
 * @param {*} stopTime state containing the stop time in html date input format (HH:MM)
 * @param {*} setAreaField state handler for the areaField state
 * @param {*} setStartDate state handler for the startDate state
 * @param {*} setStartTime state handler for the startTime state
 * @param {*} setStopDate state handler for the stopDate state
 * @param {*} setStopTime state handler for the stopTime state
 * @param {*} searchBasic helper function for conducting the "Basic" mode search
 * @param {*} searchThisMonth helper function for conducting the "This month" mode search
 * @param {*} searchPreviousMonth helper function for conducting the "Previous month" mode search
 * @param {*} searchPreviousQuarter helper function for conducting the "Previous quarter" mode search
 * @param {*} searchPreviousYear helper function for conducting the "Previous year" mode search
 * @param {*} searching state for showing the loading animation
 * @param {*} searchComplete state for chowing the checkmark when the loading is complete
 * @param {*} invalidDates state for showing an error alert notifying the user that the input start and stop dates are invalid
 * @param {*} rangeAreaName state containing the string to show for which area the reports are for
 * @param {*} rangeText state containing the string to show for which period the reports are for
 * @param {*} headers array containing the headers for the .csv file
 * @returns the completed transactions view for Reporting
 */
export const ReportingCompletedTransactions = ({
  areas,
  allTransactions,
  displayTransactions,
  selectedTransactions,
  handleSetSelectedTransactions,
  areaField,
  startDate,
  stopDate,
  setAreaField,
  setStartDate,
  setStopDate,
  searchBasic,
  search,
  searching,
  searchComplete,
  invalidDates,
  rangeAreaName,
  rangeText,
  headers,
  createInvoices,
  noRowChosen,
  createInvoiceFail,
  createInvoiceSuccess,
  user,
  notReportingSite,
  setSelectedInvoiceMethod,
  setInvoicingFilter,
  selectedInvoiceMethod,
  invoicingFilter,
  currArea,
  setCurrArea,
  checkCards,
  cardsSuccess,
  cardsFail,
}: ReportingCompletedTransactionsProps) => {
  const { t } = useTranslation("common", { i18n: i18n });
  const [componentState, setComponentState] = useState<"searching" | "ready" | "complete">("ready");
  const columnsForTable: ColumnDescription<ReportingTransaction>[] = [
    {
      dataField: "last_name",
      text: t("components.reporting.tabs.completed.table.name"),
      formatter: (cell, row, rowIndex, extraData) => `${row.first_name} ${row.last_name}`,
      sort: true,
    },
    {
      dataField: "kWh",
      text: "kWh",
      sort: true,
      sortValue: (cell, row) => Number(row.kWh),
    },
    {
      dataField: "total_cost",
      text: t("components.reporting.tabs.completed.table.price"),
      formatter: (cell, row, rowIndex, extraData) => Number(row.total_cost).toFixed(2),
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        if (order === "asc") {
          return Number(b) - Number(a);
        }
        return Number(a) - Number(b); // desc
      },
      sort: true,
    },
  ];

  const selectRowProps = createSelectRowProps<ReportingTransaction>("id", handleSetSelectedTransactions);

  const areaChanged = async (area: AreaBase) => {
    setCurrArea(area);
    setAreaField(area.name);
    //searchBasic(area);
  };

  const invoiceMethodButtons = [
    {
      name: t("components.reporting.tabs.completed.table.buttonsFilters.all"),
      key: null,
    },
    {
      name: t("components.reporting.tabs.completed.table.buttonsFilters.test"),
      key: 0,
    },
    {
      name: t("components.reporting.tabs.completed.table.buttonsFilters.normal"),
      key: 1,
    },
    { name: "Wattery/Paytrail/Stripe", key: 2 },
  ];

  const invoiceFilter = [
    {
      name: t("components.reporting.tabs.completed.table.buttonsFilters.showAll"),
      key: 0,
    },
    {
      name: t("components.reporting.tabs.completed.table.buttonsFilters.invoiced"),
      key: 1,
    },
    {
      name: t("components.reporting.tabs.completed.table.buttonsFilters.notInvoiced"),
      key: 2,
    },
  ];

  return (
    <>
      {/*Column containing the left side view*/}
      <Col className="column" xs={12} md={5}>
        <TransactionsFilters
          user={user}
          invoiceMethodButtons={invoiceMethodButtons}
          setSelectedInvoiceMethod={setSelectedInvoiceMethod}
          selectedInvoiceMethod={selectedInvoiceMethod}
          areas={areas}
          areaChanged={areaChanged}
          areaField={areaField}
          invalidDates={invalidDates}
          startDate={startDate}
          setStartDate={setStartDate}
          stopDate={stopDate}
          setStopDate={setStopDate}
          invoiceFilter={invoiceFilter}
          invoicingFilter={invoicingFilter}
          setInvoicingFilter={setInvoicingFilter}
        />
        {/*Row containing the serach button. If the searchComplete state is true, show a checkmark.
        If the state searching is true, show a loading animation. Otherwise, show the search button*/}
        <Row className="mb-3">
          {searchComplete ? ( //If true, show a checkmark
            <Button variant="outline">
              <img src={checkmarkIcon} alt="Checkmark" />
            </Button>
          ) : searching ? ( //If true show a loading animation. Otherwise, show the search button
            <Button variant="outline">
              <img src={refreshIconAnimated} alt="Loading" style={{ width: 30 }} />
            </Button>
          ) : (
            <Button variant="primary" onClick={() => searchBasic(currArea)} disabled={areas.length === 0}>
              {t("components.reporting.tabs.completed.search")}
            </Button>
          )}
        </Row>
        {/*Row containing the create invoices button*/}
        {user.user_level === 2 && (
          <>
            <Row className="mb-3">
              <Button variant="primary" onClick={() => createInvoices(selectedInvoiceMethod)}>
                {t("components.reporting.tabs.completed.create")}
              </Button>
            </Row>
          </>
        )}

        {/* CHECK CARDS BUTTON DISABLED until further notice  */}
        {/** 
        {user.user_level === 2 && (
          <Row className="mb-3">
            <Button
              variant="primary"
              onClick={() => checkCards()}
              disabled
            >
              {t('components.reporting.tabs.completed.check')}
            </Button>
          </Row>
        )}
        */}

        {areas.length > 0 ? (
          <QuickReports currArea={currArea} searching={searching} searchComplete={searchComplete} search={search} />
        ) : null}
      </Col>
      {/*Column containing the right side view*/}
      <TransactionTable
        rangeAreaName={rangeAreaName}
        rangeText={rangeText}
        selectedTransactions={selectedTransactions}
        headers={headers}
        noRowChosen={noRowChosen}
        notReportingSite={notReportingSite}
        createInvoiceSuccess={createInvoiceSuccess}
        createInvoiceFail={createInvoiceFail}
        allTransactions={allTransactions}
        displayTransactions={displayTransactions}
        columnsForTable={columnsForTable}
        selectRowProps={selectRowProps}
        cardsSuccess={cardsSuccess}
        cardsFail={cardsFail}
      />
    </>
  );
};
