import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import i18n from "../../../../i18n";
import { attachPaymentMethod, checkCardValidity } from "../../../../services/stripe/stripeService";
import { Fetchable, FormLifecycle, StateHandler } from "../../../../model/Utilities/Types";
import { StripeCustomer } from "../../../../model/Classes/StripeCustomer";

import { toast } from "react-toastify";
import { handleSetupIntentDecline } from "../../../../model/Utilities/Extensions";
import { useState } from "react";
import refreshIconAnimated from "../../../../resources/loadingIcon.svg";
import { StripePaymentElementChangeEvent } from "@stripe/stripe-js";
import { timer } from "../../../../utils/timer";

declare interface StripeCardFormProps {
  clientSecret?: string;
  customer?: StripeCustomer;
  successfullAddCard: boolean;
  setSuccessfullAddCard: StateHandler<boolean>;
  setCardsState: StateHandler<Fetchable | "new" | "changed">;
  handleModalHide: (confirmed?: boolean) => Promise<void>;
}

export default function StripeCardForm({
  clientSecret,
  customer,
  setCardsState,
  setSuccessfullAddCard,
  handleModalHide,
}: StripeCardFormProps) {
  const stripe = useStripe();
  const elements = useElements();
  const [paymentElementState, setPaymentElementState] = useState<FormLifecycle>("none");
  const { t } = useTranslation("common", { i18n: i18n });

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements || !clientSecret) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return null;
    }
    setPaymentElementState("working");

    const e = await elements.submit();

    if (e.error) {
      toast.error(t("global.alert.failure.cardError"), { autoClose: false });
      return;
    }

    const { error, confirmationToken } = await stripe.createConfirmationToken({ elements });
    if (error) {
      toast.error(t("global.alert.failure.cardError"), { autoClose: false });
      return;
    }

    const isValidCard = await checkCardValidity({
      customer_id: customer!.customer_id,
      confirmation_token: confirmationToken.id,
    });

    //An unexpected error occurred within our system
    if (!isValidCard.success) {
      toast.error(t("global.view.errorAdmin"));
    }

    //All is ok, but the card is already in our system
    if (isValidCard.data.resultCode === 15) {
      toast.warning(t("global.alert.failure.cardAlready"), { autoClose: false });
      handleModalHide(true);
      return;
    }

    //An unexpected error occurred, probably a problem with our request to Stripe
    if (isValidCard.data.resultCode === 50) {
      toast.error(t("global.alert.failure.stripe.processing_error"));
    }

    const setiRes = await stripe.confirmSetup({
      elements: elements,
      clientSecret: clientSecret,
      redirect: "if_required",
    });

    if (setiRes.error) {
      toast.error(t(handleSetupIntentDecline(setiRes.error)), { autoClose: false });
      setPaymentElementState("ready");
      return;
    }
    const seti = setiRes.setupIntent;

    //a setup intent response can return only the string id or the whole object
    const pm = seti.payment_method;
    let dpm: string = typeof pm === "string" ? pm : pm ? pm.id : "";

    const attachRes = await attachPaymentMethod({
      customer_id: customer!.customer_id,
      payment_method_id: dpm,
    });
    if (attachRes.success) {
      toast.success(t("global.alert.success.card"));
      handleModalHide();
      timer(setSuccessfullAddCard, 1000);
      setCardsState("new");
    } else {
      toast.error(t("global.alert.failure.cardError"), { autoClose: false });
      handleModalHide();
    }
  };

  function handleElementsChange(event: StripePaymentElementChangeEvent) {
    setPaymentElementState(event.complete ? "ready" : "none");
  }

  return (
    <form
      onSubmit={async (e) => {
        await handleSubmit(e);
      }}
    >
      <PaymentElement onChange={handleElementsChange} />
      <Button className="mt-3" type="submit" disabled={!stripe || !elements || paymentElementState !== "ready"}>
        {paymentElementState === "working" ? (
          <img
            className="spinner"
            src={refreshIconAnimated}
            alt="Loading"
            style={{
              width: 30,
            }}
          />
        ) : (
          t("global.buttons.submit")
        )}
      </Button>
    </form>
  );
}
