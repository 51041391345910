import { StripeCard } from "../../model/Classes/Card";
import { StripeCustomer } from "../../model/Classes/StripeCustomer";
import { ResultCode } from "../../model/Utilities/Types";
import { tokenRenewingGet, tokenRenewingPost } from "../tokenRenewingRequest";

const baseURL = "/api/user/stripe";

export async function createSetupIntent(data: { customer_id: string }) {
  return await tokenRenewingPost<string>(`${baseURL}/create/setup-intent`, data);
}

export async function cancelSetupIntent(data: { setup_intent_id?: string }) {
  return await tokenRenewingPost(`${baseURL}/cancel/setup-intent`, data);
}

export async function updateSetupIntent(options: { payment_method?: string; customer: string; setup_intent: string }) {
  return await tokenRenewingPost<string>(`${baseURL}/update/setup-intent`, options);
}

export async function attachPaymentMethod(data: { customer_id: string; payment_method_id: string }) {
  return await tokenRenewingPost<any>(`${baseURL}/update/attach`, data);
}

export async function checkCardValidity(data: { customer_id: string; confirmation_token: string }) {
  return await tokenRenewingPost<{ resultCode: ResultCode; message: string; data: any }>(`${baseURL}/check-card`, data);
}

export async function createCustomer() {
  return await tokenRenewingPost<StripeCustomer>(`${baseURL}/create/customer`);
}

export async function createPaymentIntent() {
  return await tokenRenewingPost<string>(`${baseURL}/create/payment-intent`);
}

export async function retrieveCustomerCards() {
  return await tokenRenewingGet<StripeCard[]>(`${baseURL}/retrieve/cards`);
}

export async function getDefaultCard() {
  return await tokenRenewingGet<StripeCard[]>(`${baseURL}/retrieve/cards/default`);
}

export async function retrieveCustomer() {
  return await tokenRenewingGet<StripeCustomer>(`${baseURL}/retrieve/customer`);
}

export async function chargeCard(data: { user_invoice_id: number }) {
  return await tokenRenewingPost<{ resultCode: ResultCode; message: string; data: any }>(
    "/api/admin/invoicing/charge",
    data
  );
}

export const chargeUserInvoices = async () => {
  return await tokenRenewingPost<{ resultCode: ResultCode; message: string; data: any }[]>("/api/user/invoices/charge");
};
